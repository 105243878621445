import React from "react"
import { Link } from "gatsby"

export const WhyChooseUs = () => {
  return (
    <section className="why-choose-us  text-white">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-6 col-lg-5">
            <h3 className="mb-5">Why Choose Us</h3>
            <p>
              We are passionate about and cater specifically for lifestyle block
              animals. We understand that every lifestyle farm is different and
              the challenges that may represent. Contact the friendly team at
              Lakeside Lifestyle Animal Health today to see how they can help
              with keeping your animals healthy.
            </p>

            <Link to="/contact/" className="btn btn-secondary mt-4">
              Get free quote
            </Link>
          </div>
          <div className="mt-5 mt-lg-0 col-lg-6 d-none">
            <h3 className="mb-5">Get Free Quote</h3>
            <form
              method="post"
              netlify-honeypot="bot-field"
              data-netlify="true"
              name="Contact"
              action="/thank-you"
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="Contact" />
              <div className="form-group">
                <div className="form-group">
                  <label htmlFor="name" className="sr-only">
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Full Name"
                    name="name"
                    required
                  />
                </div>
                <label htmlFor="email" className="sr-only">
                  Email address
                </label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter email"
                  name="email"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone" className="sr-only">
                  Phone Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Phone Number"
                  name="phone"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="message" className="sr-only">
                  Message
                </label>
                <textarea
                  className="form-control"
                  rows="5"
                  placeholder="Message"
                  name="message"
                  required
                />
              </div>

              <button type="submit" className="btn btn-secondary">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyChooseUs
