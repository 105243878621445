import * as React from "react"
import { Navbar, Nav } from "react-bootstrap"
import { Link } from "gatsby"

const Header = () => (
  <>
    <header className="bg-light">
      <div className="container">
        <Navbar expand="lg" className="px-0">
          <Link className="navbar-brand" to="/">
            <img
              className="img-fluid"
              src="/logo.png"
              alt="Lakeside Lifestyle logo"
              width={320}
            />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Link to="/" className="nav-link" activeClassName="active">
                About
              </Link>
              <Link
                className="nav-link"
                activeClassName="active"
                to="/services"
              >
                Services
              </Link>
              <Link
                className="nav-link"
                activeClassName="active"
                to="/meet-the-team/"
              >
                Meet the team
              </Link>
              <Link
                to="/contact/"
                className="btn btn-secondary align-items-center d-flex text-uppercase"
              >
                <span>Contact</span>
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
    <div className="alert alert-danger text-center mb-0 rounded-0">
      <div className="container">
        IF YOUR ANIMAL IS SICK OR INJURED PLEASE CONTACT YOUR PRIMARY GENERAL
        VETERINARY PRACTICE
      </div>
    </div>
  </>
)

export default Header
