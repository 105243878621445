/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"

import Header from "./header"
import Footer from "./footer"
import "../styles/main.scss"
// import then needed Font Awesome functionality
import { library, dom } from "@fortawesome/fontawesome-svg-core"
// import the Facebook and Twitter icons
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons"
import {
  faMapMarkerAlt,
  faEnvelope,
  faPhone,
  faClock,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons"

import WhyChooseUs from "./why-choose-us"

// add the imported icons to the library
library.add(
  faFacebookSquare,
  faMapMarkerAlt,
  faEnvelope,
  faPhone,
  faClock,
  faCheckCircle
)

const Layout = ({ children }) => {
  // tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
  dom.watch()

  return (
    <>
      <Header />
      <main>{children}</main>
      <WhyChooseUs />
      <Footer />
    </>
  )
}

export default Layout
