import React from "react"
import { Link } from "gatsby"
import { Nav } from "react-bootstrap"

const Footer = () => (
  <footer className="site-footer">
    <div className="container position-relative">
      <div className="row justify-content-between">
        <div className="col-lg-4">
          <h5 className="mb-4">About</h5>
          <p className="mb-0">
            From vaccinations to NAIT compliance, foot trimming to pasture
            management plans, Lakeside Lifestyle is here to help!
          </p>
        </div>
        <div className="col-lg-3 mt-4 mb-3 m-lg-0">
          <h5 className="mb-4">Contact</h5>
          <ul className="fa-ul">
            <li className="pb-2">
              <span className="fa-li">
                <i className="fas fa-phone"></i>
              </span>
              <a href="tel:021 141 6486">021 141 6486</a>
            </li>
            <li className="pb-2">
              <span className="fa-li">
                <i className="fas fa-envelope"></i>
              </span>
              <a href="mailto:admin@lakesidelifestyle.co.nz">
                admin@lakesidelifestyle.co.nz
              </a>
            </li>
            <li>
              <span className="fa-li">
                <i className="fab fa-facebook-square"></i>
              </span>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.facebook.com/lakesideanimals"
              >
                Find us on Facebook
              </a>
            </li>
          </ul>
        </div>
        <div className="col-lg-4">
          <div className="footer__menu">
            <h5 className="mb-4">Services</h5>
            <Nav className="flex-column">
              <Link
                activeClassName="active"
                className="nav-link pl-0 pt-0"
                to="/services/preventative-health-services/"
              >
                Preventative Health Services
              </Link>
              <Link
                activeClassName="active"
                className="nav-link pl-0 pt-0"
                to="/services/animal-health-plans/"
              >
                Animal Health Plans
              </Link>
              <Link
                activeClassName="active"
                className="nav-link pl-0 pt-0"
                to="/services/lifestyle-block-package/"
              >
                Lifestyle Block Start-Up Package
              </Link>
              <Link
                activeClassName="active"
                className="nav-link pl-0 pt-0"
                to="/services/nait-compliance/"
              >
                NAIT Compliance
              </Link>
            </Nav>
          </div>
        </div>
      </div>

      <p className="footer__copyright text-center  mb-0">
        Website by <a href="https://sixvees.co.nz">Sixvees</a>.
      </p>
    </div>
  </footer>
)

export default Footer
